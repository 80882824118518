import React from "react";
import { Container } from "../global";
import styled from "styled-components";
import BackgroundImage from "../../images/stay-alert/stayalert-bg.png";
import LogoBackground from "../../images/stay-alert/logo-bg.svg";
import OPayLogo from "../../images/stay-alert/stayalert-opay-logo.svg";
import Phone from "../../images/stay-alert/phone.png";

const StayAlertMessage = () => {
  return (
    <Container>
      <Wrapper>
        <Rectangle>
          <Overlay>
            <LogoWrapper>
              <LogoBackground />
              <Logo>
                <OPayLogo />
              </Logo>
            </LogoWrapper>
            <PhoneWrapper>
              <PhoneImage src={Phone} alt="Phone" />
            </PhoneWrapper>
            <TextGroup>
              <Title>Stay Alert!</Title>
              <Description>
                Remember, do not disclose your personal details like your BVN, NIN, Passwords and PIN to strangers.
              </Description>
              <Disclaimer>(Even when they claim to represent popular brands)</Disclaimer>
            </TextGroup>
          </Overlay>
        </Rectangle>
      </Wrapper>
    </Container>
  );
};

export default StayAlertMessage;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 140px 0 0 0;

  ${({ theme }) => theme.mq.lg`
        padding: 180px 0 80px 0;
    `}
`;

const Rectangle = styled.div`
  width: 600px;
  height: 655px;
  background-color: ${({ theme }) => theme.colors.stayAlert};

  ${({ theme }) => theme.mq.lg`
        width: 400px;
        height: 650px;
    `};
`;

const Overlay = styled.div`
  background-image: url(${BackgroundImage});
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
    background-image: url(${BackgroundImage});
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: cover;
    width: 400px;
    height: 650px;
    `}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.span`
  position: absolute;
  left: 35px;

  ${({ theme }) => theme.mq.xl`
        position: fixed;
        left: 650px;
        top: 195px;
    `}
`;

const PhoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 23px;
  width: 300px;

  ${({ theme }) => theme.mq.lg`
        margin: 0 auto;
        padding: 20px 30px 20px 0;
    `}
`;

const PhoneImage = styled.img`
    /* ${({ theme }) => theme.mq.lg`
        margin: 0;
    `} */
`;

const TextGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 275px;
  text-align: center;
  font-size: 17px;
`;

const Title = styled.h3`
  font-weight: 600;
  padding: 0 0 20px 0;
  font-size: 36px;
`;

const Description = styled.p`
  font-weight: 500;
`;

const Disclaimer = styled.span``;
