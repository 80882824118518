import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Footer from "../components/sections/footer";
import StayAlertMessage from "../components/stay-alert";

const StayAlert = () => {
  return (
    <Layout>
      <SEO title="OPay - Stay Safe" />
      <Navigation background="white" />
      <StayAlertMessage />
      <Footer />
    </Layout>
  );
};

export default StayAlert;
